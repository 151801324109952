<template>
    <section class="content">
        <div class="w-100 card">
        <div class="card-body">
			<h3 style="text-align: center;">Daftar Nilai Siswa</h3>
			<table>
				<tr>
					<td>Kelas</td>
					<td style="text-align: center;width: 15%;">:</td>
					<td>{{info.kelas}}</td>
				</tr>
				<tr>
					<td>Mata Pelajaran</td>
					<td style="text-align: center;width: 15%;">:</td>
					<td>{{info.mapel}}</td>
				</tr>
			</table>
        </div>
        </div>
        <div class="p-1"></div>
        <div class="card">
            <div class="card-header d-flex align-items-center">
                <h3 class="card-title">Hasil Ujian</h3>
                <button class="btn btn-success ml-auto" @click="downloadxlsx()">Download</button>
            </div>
            <div class="card-body">
                <table class="table table-hover table-bordered text-center table-responsive" ref="tablesuswa" id="myTable" style="width: 100%;">
                    <thead>
                        <tr>
                            <th rowspan="3" style="width:60px;vertical-align: middle;">NAMA SISWA</th>
                            <th v-for="data_header in info.data_header" :colspan="data_header.sub_soal.length * 4">{{ data_header.ujian_title }}</th>
                        </tr>
                        <tr>
                            <th v-for="data_header in info.data_header2" :colspan="4">{{data_header.tipe}}</th>
                        </tr>
                        <tr>
                            <template v-for="data_header in info.data_header2">
                                <th data-dt-order="disable">Benar</th>
                                <th data-dt-order="disable">Salah</th>
                                <th data-dt-order="disable">Tidak Terjawab</th>
                                <th data-dt-order="disable">Jumlah Soal</th>
                            </template>
                        </tr>
                    </thead>
                    <tbody @click="handleClick">
                        <tr v-for="data_body in dataBody">
                            <td>{{data_body.username}}</td>
                            <template v-if="dataBodyNotEmpty(data_body)">
                                <template v-for="(data, index) in info.data_header2">
                                    <template v-if="checkSameKecermatanId(data.id, data_body.siswa_id)">
                                        <td>{{ checkSameKecermatanId(data.id, data_body.siswa_id).jawaban_benar }}</td>
                                        <td>{{ checkSameKecermatanId(data.id, data_body.siswa_id).jawaban_salah }}</td>
                                        <td>{{ checkSameKecermatanId(data.id, data_body.siswa_id).tidak_terjawab }}</td>
                                        <td>{{ parseInt(checkSameKecermatanId(data.id, data_body.siswa_id).terjawab) + parseInt(checkSameKecermatanId(data.id, data_body.siswa_id).tidak_terjawab) }}</td>
                                    </template>
                                    <template v-else>
                                        <td colspan="4">Belum ada nilai</td>
                                    </template>
                                </template>
                            </template>
                            <template v-else>
                                <td :colspan="getColspan()">Belum ada nilai</td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
import Swal from "sweetalert2";
import moment from "moment";
window.JSZip = require('jszip')
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    name: 'DetailHasilUjianSiswa',
    data() {
        return {
            errors: [],
            siswa: {},
            info: [],
            test:{},
            dataBody: [], 
            method: '',
            roles: '',
            idKelas: '',
            idMapel: '',
            idPaket: '',
            idProgram: '',
            branch: ''
        }
    },
    components: {
        datepicker
    },
    computed: {
        dataBodyNotEmpty() {
            return function(data_body) {
                return data_body.data_rekap && data_body.data_rekap.length > 0;
            }
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)
        this.idPaket = this.$route.params.paket_id
        this.idMapel = this.$route.params.mapel_id
        this.idKelas = this.$route.params.kelas_id
        this.idProgram = this.$route.params.program_id
        authFetch("/akademik/ujian/rekap_info_kecermatan/" + this.idKelas + '/34/' + this.idProgram +'/'+this.idPaket).then((res) => {
            res.json().then((json) => {
                this.info = json;
                this.loadTable();
            });
        });
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
        },
        loadTable: function() {
            var data = {
                'kelas_id' : this.idKelas,
                'mapel_id' : 34,
                'program_id' : this.idProgram,
                'paket_id' : this.idPaket
            }
            var payload = Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&')
            authFetch("/akademik/ujian/rekap_ujian_kecermatan",{
                method: 'POST',
                body: payload
            }).then((res) => {
                res.json().then((json) => {
                    this.dataBody = json
                    console.log(this.dataBody)
                });
            });
        },
        getColspan() {
            const thCount = this.info.data_header2.length * 4; 
            return thCount;
        },
        checkSameKecermatanId(data_rekap_kecermatan_id, siswa) {
            var databody = this.dataBody;
            var dataperson = databody.find(o => o.siswa_id == siswa);
            var a = dataperson.data_rekap;
            return a.find(y => y.kecermatan_id == data_rekap_kecermatan_id);
        },
        // downloadxlsx() {
        //     var tab_text = "<table border='2px'><tr bgcolor='#87AFC6'>";
        //     var j = 0;
        //     var tab = document.getElementById('myTable'); // id of table

        //     for (j = 0; j < tab.rows.length; j++) {
        //         tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";
        //         //tab_text=tab_text+"</tr>";
        //     }

        //     tab_text = tab_text + "</table>";
        //     tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, "");//remove if u want links in your table
        //     tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
        //     tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

        //     var msie = window.navigator.userAgent.indexOf("MSIE ");
        //     var sa = null
        //     // If Internet Explorer
        //     if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        //         txtArea1.document.open("txt/html", "replace");
        //         txtArea1.document.write(tab_text);
        //         txtArea1.document.close();
        //         txtArea1.focus();

        //         sa = txtArea1.document.execCommand("SaveAs", true, "Say Thanks to Sumit.xls");
        //     } else {
        //         // other browser not tested on IE 11
        //         sa = window.open('data:application/vnd.ms-excel,' + encodeURIComponent(tab_text));
        //     }

        //     return sa;
        // },
        downloadxlsx() {
            var tab_text = "<table border='2px'><tr bgcolor='#87AFC6'>";
            var j = 0;
            var tab = document.getElementById('myTable'); // id of table

            for (j = 0; j < tab.rows.length; j++) {
                tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";
                //tab_text=tab_text+"</tr>";
            }

            tab_text = tab_text + "</table>";
            tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, "");//remove if u want links in your table
            tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
            tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

            var msie = window.navigator.userAgent.indexOf("MSIE ");

            // If Internet Explorer
            if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
                txtArea1.document.open("txt/html", "replace");
                txtArea1.document.write(tab_text);
                txtArea1.document.close();
                txtArea1.focus();

                sa = txtArea1.document.execCommand("SaveAs", true, "Say Thanks to Sumit.xls");
            } else {
                // other browser not tested on IE 11
                sa = window.open('data:application/vnd.ms-excel,' + encodeURIComponent(tab_text));
            }

            return sa;
        }
    },
    mounted() {
        
    }
}
</script>
<style type="text/css">
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
</style>